class CheckInsPane {
  constructor(el) {
    this.el = el;

    /** Accordions **/
    document.querySelectorAll('.nei-accordion').forEach( function(el){
      new NeiAccordion(el, true);
    });

    let uid = AppState.getUid();
    if(uid) {
      this.el.querySelector('[data-check-ins-code]').innerText = uid.split('-')[4];

      ApiHelper.getCheckIns(uid).then( response => {
        const checkInsCount = response.length;

        this.el.querySelector('[data-check-ins-message]').innerText = NotificationHelper.getCheckInText(checkInsCount);
        this.el.querySelector('[data-check-ins-count]').innerText = checkInsCount;
        this.el.querySelector('.qr-check-ins__progress-bar-fill').style.width = `calc((100%/45) * ${checkInsCount})`;

        const progressBarEl = this.el.querySelector('.qr-check-ins__progress-bar');

        if(checkInsCount >= 85)
          progressBarEl.setAttribute('data-stage', '85');
        else if(checkInsCount >= 80)
          progressBarEl.setAttribute('data-stage', '80');
        else if(checkInsCount >= 70)
          progressBarEl.setAttribute('data-stage', '70');
        else if(checkInsCount >= 50)
          progressBarEl.setAttribute('data-stage', '50');
        else if(checkInsCount >= 30)
          progressBarEl.setAttribute('data-stage', '30');

        const checkInListItems = response.map( checkInData => `<li><a href="/actividade?id=${checkInData.activity_id}">${checkInData.activity_name}</a></li>` );

        this.el.querySelector('[data-check-ins-list]').innerHTML = checkInListItems.join('\n');
      });
    }
  }
}
